<template>
  <v-textarea
    v-model="inputVal"
    :label="label"
    :rules="rules"
    :value="value ? value : ''"
  ></v-textarea>
</template>

<script>
export default {
  props: ["label", "value", "rules", "type", "prefix"],
  data: () => ({
    name: null,
    rules: [(v) => !!v || "Name is required"],
  }),
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style>
</style>