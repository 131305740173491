<template>
  <span>
    <v-file-input
      v-model="inputVal"
      show-size
      accept="image/png, image/jpeg, image/bmp"
      prepend-icon="mdi-camera"
      :label="label"
    ></v-file-input>
    <v-img
      v-if="url"
      max-height="50"
      max-width="50"
      :src="url"
      class="mt-3 mr-auto"
    ></v-img>
    <v-img
      v-if="!url"
      max-height="50"
      max-width="50"
      :src="inputVal"
      class="mt-3 mr-auto"
    ></v-img>
  </span>
</template>

<script>
export default {
  props: [
    "label",
    "value",
    "rules",
    "type",
    "prefix",
    "callbackSuccess",
    "callbackPreview",
    "model",
  ],
  data: () => ({
    logo: [],
    url: null,
    selectedFile: null,
  }),
  created(){
    
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        if (val) {
          this.url = URL.createObjectURL(val);
        } else {
          this.url = null;
        }
        this.$emit("input", val);
      },
    },
  },
  watch: {
    logo: function (val) {
      this.logo = val;
      if (val) {
        this.url = URL.createObjectURL(val);
      } else {
        this.url = null;
      }

      this.callbackPreview(this.url);
    },
  },
  mounted() {
    if (this.value) {
      this.url = this.value;
    }
  },
  methods: {
    //BOC:[form]
    //EOC
  },
};
</script>

<style>
</style>