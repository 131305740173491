<template>
  <span>
    <v-file-input
      v-model="inputVal"
      show-size
      accept="audio/*"
      prepend-icon="mdi-microphone"
      :label="label"
    ></v-file-input>
    
    <audio v-if="url" :src="url" controls class="mt-3 mr-auto"></audio>
    <audio v-if="!url" :src="inputVal" controls class="mt-3 mr-auto"></audio>
  </span>
</template>

<script>
export default {
  props: [
    "label",
    "value",
    "rules",
    "type",
    "prefix",
    "callbackSuccess",
    "callbackPreview",
    "model",
  ],
  data: () => ({
    audio: [],
    url: null,
    selectedFile: null,
  }),
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        if (val) {
          this.url = URL.createObjectURL(val);
        } else {
          this.url = null;
        }
        this.$emit("input", val);
      },
    },
  },
  watch: {
    audio: function (val) {
      this.audio = val;
      if (val) {
        this.url = URL.createObjectURL(val);
      } else {
        this.url = null;
      }

      this.callbackPreview(this.url);
    },
  },
  mounted() {
    if (this.value) {
      this.url = this.value;
    }
  },
  methods: {
  },
};
</script>

<style>
</style>
